declare var $:any;

var pc_mode;
var pre_mode;

$(function(){
/*
    if(location.hash){
        var id = location.hash.substr(1);
    }
*/
    // モード切替
    pc_mode = pre_mode = !(window.innerWidth < 960);
    $(window).on("resize", function(){
        pc_mode = !(window.innerWidth < 960);
        if(pre_mode != pc_mode){
            initNav();
            setupNav();
            pre_mode = pc_mode;
        }
        if(pc_mode){
            $("#global-nav").show();
        }
    });
    
    // ナビゲーション設定
    initNav();
    setupNav();
    
    // 未読アイコン
    $("#unread").attr({"title": "未読にする"}).text("").click(function(){
        let index = window.location.href.split("/").pop();
        window.location.href = "/index.page?unread=" + index;
    });
    
    // ヘッダをクリックでナビゲーションを表示
    $("header .mobile").click(function() {
        if(!pc_mode){
            showNav();
            return false;
        }
    });
    
    // クリックでナビゲーションを閉じる
    $("#global-nav").click(function() {
        if(!pc_mode) showNav(true);
    });
    
    // ページトップ表示設定
    var top = $("#top");
    top.click(function(e){
        $("html,body").animate({scrollTop:0}, "normal");
        return false;
    });
    
    // スクロールイベント
    $(window).scroll(function(){
        var hh = $("header").height();
        var sctop = $(this).scrollTop();
        if(sctop > hh){
            top.slideDown();
            if(pc_mode){
                $("#global-nav nav").css({
                    position: "fixed",
                    top: 0,
                    left: 0,
                });
                $("#page").css("margin-left", "18rem");
            }
        }else{
            top.slideUp();
            if(pc_mode){
                $("#global-nav nav").css("position", "static");
                $("#page").css("margin-left", "0rem");
            }
        }
    });
    
    // イメージ拡大表示設定
    setupImegeView();
});



function setupImegeView(){
    $("<div>").attr("id", "img-frame").appendTo("body").hide();
    
    $("#page img").each(function(i, elem){
        $(this).click(function(){
            if(!pc_mode) return;
            var img = $(this).clone();
            $("#img-frame").append(img).fadeIn();
            img.attr("title", "クリックで閉じます")
            img.parent().click(function(){
                $("#img-frame").fadeOut(function(){
                    img.remove();
                });
            });
        });
    });
}


function initNav(){
    if(!pc_mode){
        showNav();showNav(true);
    }
    $("#global-nav ul ul").hide();
    $(".selected").parents("ul").show();
    $("#global-nav ul ul ul").parent().children("span").removeClass("open");
    $("#global-nav ul ul ul a.selected").parents("li").children("span").addClass("open");
}

function setupNav(){
    $("#global-nav ul li span").off("click").click(function(){
        let elem = $(this).next();
        if($(this).hasClass("open")){
            elem.slideUp();
            $(this).removeClass("open");
        }else{
            elem.slideDown();
            $(this).addClass("open");
        }
        return false;
    });
}


function showNav(hide=false){
    let nav = $("#global-nav")
    if(nav.hasClass("show") || hide){
        nav.removeClass("show");
        setTimeout(() => {
            nav.hide();
        }, 500);
    }else{
        nav.show();
        nav.addClass("show");
    }
}
